.text-login{ 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: top;
    size: 20px;
    top: -120px;
    left: -12%;
    color: #F5F5DC;
    text-align: center;
} 

.text-login-mob{ 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: top;
    size: 20px;
    top: -220px;
    left: -40px;
    color: #F5F5DC;
    text-align: center;
} 


.text-color{
    color: white;
    text-align: center;
}
.logo-style{ 
    max-width: 1200px; 
    max-height: 800px;
} 

.alert-dialog-style{ 
    margin:10px;
}

.btn-transparent{
    background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    // background-image: url('/username.svg');
    // position: relative;
    // top: -140px;
    // background-color: #ffffff00;
    // background-size: contain;
    // background-repeat: no-repeat;
    // border: none; /* Remove border */
    // color: #F5F5DC;
    // background-position: left top;
    // padding: 50px;
    // width: 250px;
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    // background-image: url('/username.svg');
    // position: relative;
    // top: -140px;
    // background-color: #ffffff00;
    // background-size: contain;
    // background-repeat: no-repeat;
    // border: none; /* Remove border */
    // color: #F5F5DC;
    // background-position: left top;
    // padding: 50px;
    // width: 250px;
    background-color: transparent !important;
}

textarea:focus, input:focus{
    outline: none;
}

.button-mob-login-registerx {
    // background-image: url('/username.svg');
    position: relative;
    top: -190px;
    background-color: #ffffff00;
    background-size: contain;
    background-repeat: no-repeat;
    // border: none; /* Remove border */
    color: #F5F5DC;
    background-position: left top;
    // padding: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 35px;
    padding: 7px;
    width: 250px;
    border: 4px solid; /* Set border width */
    border-color: #e1c66f; /* Set border color */
    // border-image-slice: 1; /* Use the entire gradient */
    // border-image-source: linear-gradient(to right, #FFD700, #8d844b, #FFD700, #8d844b); /* Gold gradient border */

    border-radius: 25px; /* Curved borders */
}

.button-mob-login-2-registerx {
    // background-image: url('/username.svg');
    position: relative;
    top: -175px;
    left: 0px;
    background-color: #ffffff00;
    background-size: contain;
    background-repeat: no-repeat;
    // border: none; /* Remove border */
    color: #F5F5DC;
    background-position: left top;
    // padding: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 35px;
    // padding-top: 30px;
    padding: 7px;
    width: 250px;
    border: 4px solid; /* Set border width */
    border-color: #e1c66f; /* Set border color */
    // border-image-slice: 1; /* Use the entire gradient */
    // border-image-source: linear-gradient(to right, #FFD700, #8d844b, #FFD700, #8d844b); /* Gold gradient border */

    border-radius: 25px; /* Curved borders */
}

.button-mob-login-3-registerx {
    // background-image: url('/username.svg');
    position: relative;
    top: -205px;
    left: 0px;
    background-color: #ffffff00;
    background-size: contain;
    background-repeat: no-repeat;
    // border: none; /* Remove border */
    color: #F5F5DC;
    background-position: left top;
    // padding: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 35px;
    // padding-top: 30px;
    padding: 7px;
    width: 250px;
    border: 4px solid; /* Set border width */
    border-color: #e1c66f; /* Set border color */
    // border-image-slice: 1; /* Use the entire gradient */
    // border-image-source: linear-gradient(to right, #FFD700, #8d844b, #FFD700, #8d844b); /* Gold gradient border */

    border-radius: 25px; /* Curved borders */
}

.button-mob-login-4-registerx {
    // background-image: url('/username.svg');
    position: relative;
    top: -215px;
    background-color: #ffffff00;
    background-size: contain;
    background-repeat: no-repeat;
    // border: none; /* Remove border */
    color: #F5F5DC;
    background-position: left top;
    // padding: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 35px;
    // padding-top: 30px;
    padding: 7px;
    width: 250px;
    border: 4px solid; /* Set border width */
    border-color: #e1c66f; /* Set border color */
    // border-image-slice: 1; /* Use the entire gradient */
    // border-image-source: linear-gradient(to right, #FFD700, #8d844b, #FFD700, #8d844b); /* Gold gradient border */

    border-radius: 25px; /* Curved borders */
}

.login-form {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    position: fixed;
    justify-content: center;
    align-items: top;
    width: 350px;
    top: 30%;
    left: 15%;
}
/* input { background-image:url('/username.svg') } */

.passwordInput {
    /* margin-bottom: 2rem; */
    background-image: url('/password.svg') #ffffff 2.5% left top cover;
  }
/* Adjust background size for text input */
.button-mob-login, .button-mob-login-2 {
    background-size: 100% 100%;
}

.button-login input, .button-login-2 input {
    background-color: transparent;
}

.btn-primary-outline {
    /* background-color: black;
    border-color: #ccc; */
  }

.backgroundImageStyle {
    /* Replace 'your-image-path.jpg' with the path to your image */
    background-image: url('/bg.jpeg');
    /* Set background size, position, and other properties as needed */
    min-height: 100vh; 
    background-size: cover;
    background-position: center;
    background-color: black;
    /* Optionally, you can add other background properties like repeat, attachment, etc. */
  }


  .backgroundImageStyleMob {
    /* Replace 'your-image-path.jpg' with the path to your image */
    background-image: url('/bgMob.jpeg');
    /* Set background size, position, and other properties as needed */
    min-height: 100vh; 
    background-size: cover;
    background-position: center;
    background-color: black;
    /* Optionally, you can add other background properties like repeat, attachment, etc. */
  }

  .warning-message-mob{
    position: fixed;
    top: 1%;
    left: 60px;
}