.text-login{ 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: top;
    size: 20px;
    top: -120px;
    left: -12%;
    color: #F5F5DC;
    text-align: center;
} 

.text-login-2{ 
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: top;
    size: 20px;
    top: 18%;
    left: 10%;
    color: #F5F5DC;
    text-align: center;
} 


.text-color{
    color: white;
    text-align: center;
}
.logo-style{ 
    max-width: 1200px; 
    max-height: 800px;
} 

.alert-dialog-style{ 
    margin:10px;
}

.btn-transparent{
    background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    // background-image: url('/username.svg');
    // position: relative;
    // top: -140px;
    // background-color: #ffffff00;
    // background-size: contain;
    // background-repeat: no-repeat;
    // border: none; /* Remove border */
    // color: #F5F5DC;
    // background-position: left top;
    // padding: 50px;
    // width: 250px;
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    // background-image: url('/username.svg');
    // position: relative;
    // top: -140px;
    // background-color: #ffffff00;
    // background-size: contain;
    // background-repeat: no-repeat;
    // border: none; /* Remove border */
    // color: #F5F5DC;
    // background-position: left top;
    // padding: 50px;
    // width: 250px;
    background-color: transparent !important;
}

textarea:focus, input:focus{
    outline: none;
}

.button-login {
    background-image: url('/username.svg');
    position: relative;
    top: -140px;
    background-color: #ffffff00;
    background-size: contain;
    background-repeat: no-repeat;
    border: none; /* Remove border */
    color: #F5F5DC;
    background-position: left top;
    padding: 50px;
    width: 250px;
}

.button-login-2 {
    background-image: url('/username.svg');
    position: relative;
    top: -195px;
    background-color: #ffffff00;
    background-size: contain;
    background-repeat: no-repeat;
    border: none; /* Remove border */
    color: #F5F5DC;
    background-position: left top;
    padding: 50px;
    width: 250px;
}

.login-form {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    position: fixed;
    justify-content: center;
    align-items: top;
    width: 350px;
    top: 30%;
    left: 15%;
}
/* input { background-image:url('/username.svg') } */

.passwordInput {
    /* margin-bottom: 2rem; */
    background-image: url('/password.svg') #ffffff 2.5% left top cover;
  }
/* Adjust background size for text input */
.button-login, .button-login-2 {
    background-size: 100% 100%;
}

.button-login input, .button-login-2 input {
    background-color: transparent;
}

.btn-primary-outline {
    /* background-color: black;
    border-color: #ccc; */
  }

.backgroundImageStyle {
    /* Replace 'your-image-path.jpg' with the path to your image */
    background-image: url('/bg.jpeg');
    /* Set background size, position, and other properties as needed */
    min-height: 100vh; 
    background-size: cover;
    background-position: center;
    background-color: black;
    /* Optionally, you can add other background properties like repeat, attachment, etc. */
  }


  .backgroundImageStyleMob {
    /* Replace 'your-image-path.jpg' with the path to your image */
    background-image: url('/bgMob.jpeg');
    /* Set background size, position, and other properties as needed */
    min-height: 100vh; 
    background-size: cover;
    background-position: center;
    background-color: black;
    /* Optionally, you can add other background properties like repeat, attachment, etc. */
  }